.form-file-upload {
  height: 250px;
  width: 300px;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
  justify-content: center;
}

.input-file-upload {
  display: none;
}

.label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: #DDDDDD;
  background-color: #FFFFFF;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
  color: #999999;
}

.upload-button:hover {
  text-decoration-line: underline;
}
