@use "@scss/settings/responsives.scss" as responsives;

.fileUpload {
  width: 100%;

  .form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.formBody {
  display: flex;
  flex-direction: column;
}

.separator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;

  hr {
    width: 9.125rem;
    border: 0.063rem solid #333333;

    &:first-of-type {
      margin-right: 1rem;
    }

    &:last-of-type {
      margin-left: 1rem;
    }
  }
}

.formGroup {
  position: relative;
  display: flex;
  width: auto;
}

.externalLink {
  height: 3.25rem !important;
  width: 37.125rem !important;
  border: 0.063rem solid #999999;
  text-align: center;
  margin: auto;
  outline: 0.063rem solid;
}

.container_link {
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.buttonToolbar {
  display: flex;
  justify-content: center;
}

@include responsives.resolution__laptop__s {
  .fileUpload {
    .form {
      align-items: flex-start;
      .row {
        justify-content: center;
        .formGroup {
          justify-content: center;
          margin: 0;
        }
      }

      .row,
      .separator,
      .containerLink {
        justify-content: center;
        width: 100%;
      }
    }
  }
}

@include responsives.resolution__tablet {
  .fileUpload {
    .form {
      align-items: center;
      .row {
        .formGroup {
          justify-content: center;
        }
      }

      .row,
      .separator,
      .containerLink {
        justify-content: center;
        width: 100%;
      }
    }
  }
}

@include responsives.resolution__mobile {
  .fileUpload {
    a {
      margin: 0;
    }
  }
}
