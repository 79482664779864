.not-found__content {
  height: 70vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  .code {
    font-size: 120px;
  }

  .not-found__info {
    margin: 30px 0;
  }
}

.not-found__image {
  max-width: 500px;
  width: 100%;
}
