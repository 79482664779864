.iconTitleSidebar {
  padding: 0 20px;
}

h3 > span {
  padding-right: 0.5rem;
}

.title__mdi__icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 20px;
  padding: 0;

  &:last-child {
    margin-bottom: 20px;
  }

  svg {
    margin-right: 0.4rem;
  }
}
