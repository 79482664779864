@use "@scss/settings/responsives" as responsives;

.companySign {
  text-align: right;
  color: black;
  font-size: 0.65rem;

  @include responsives.resolution__mobile {
    text-align: center;
  }

  img {
    width: 16%;
    vertical-align: middle;

    @include responsives.resolution__mobile {
      width: 20%;
    }
  }

  a {
    padding-left: 0.3rem;
  }
}
