.dropzone {
  width: 300px;
  margin: 1.5rem auto;
  background-color: #f6f6f6;
  border: 1px solid #eaeaea;

  &.fixedHeight {
    height: 250px;
  }
}

.dropzoneImage {
  img {
    object-fit: contain;
    width: inherit;
    max-width: 100%;
    padding: 0 !important;
  }

  video {
    object-fit: cover;
  }
}

.dropzoneInput {
  width: 300px;
  min-height: 248px;
}

.dropzoneImageDelete {
  right: 10px;
}

.disabled {
  background-color: #f2f4f7;

  .dropzoneInput {
    &:hover {
      background-image: unset !important;
      animation: 0 !important;
    }
  }
}
