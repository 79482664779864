.media__badge__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.5rem;
  padding: 0 0.5rem 0 0.5rem;
}

.media__badge__item {
  span {
    text-align: center;
    font-size: 0.6rem;
    font-weight: 400;
    font-style: italic;
    text-transform: uppercase;
  }
}
