.loader {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader__insideThing {
  height: auto;
  padding: 50px 0;
}

.loader__spinner__text {
  margin: 1rem;
}
