@use "@scss/settings/responsives.scss" as responsives;

.container__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 80%;
  margin: 0 auto;
  margin-right: auto;
  align-items: flex-start;
  justify-content: center;

  @include responsives.resolution__laptop__l {
    max-width: 100%;
  }
}
