@use "@scss/settings/responsives" as responsives;
@use "@scss/settings/mixins" as mixins;

.socialMedia {
  @include mixins.flex_container(row, space-between, flex-end);

  > a {
    img {
      width: 24px;
      height: 24px;
    }
  }

  > a:not(:first-of-type) {
    padding-left: 0.3rem;
  }
}
