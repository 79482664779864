.place__card {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid #646777;
  width: calc(25% - 1rem);
  max-width: calc(25% - 1rem);
  min-width: 200px;
  height: 80px;
  text-align: center;
  text-transform: capitalize;

  a {
    margin: 0;
    color: black;
  }

  &:hover {
    background-color: #c2feda;
    cursor: pointer;
    font-weight: bold;
    font-style: italic;
  }
}

.anchor__overlay::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
