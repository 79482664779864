.filePreview {
  max-width: 20rem;
  margin-bottom: 4rem;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  p {
    width: 100%;
    text-align: center;
    font-style: italic;
  }
}
