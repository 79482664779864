@use "@scss/settings/responsives.scss" as responsives;

.boxPreview {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 20rem;
  height: 100%;
  padding: 0;

  img {
    width: 5rem;
  }

  .imagePreview {
    width: 100%;
    border: 1px solid black;
  }

  p {
    width: 100%;
    text-align: center;
    font-style: italic;
  }
}
