.buttonToolbar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 22rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  p {
    width: 100%;
    text-align: center;
    font-style: italic;
  }
}
