.media__button__toolbar {
  display: flex;
  gap: 0.7rem;
  margin-top: 0.5rem;
  width: 100%;
  justify-content: center;
  align-items: center;

  a {
    margin: 0px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 30px;
    height: 30px;

    svg {
      margin: 0;
      width: 24px;
      height: 24px;
    }
  }
}
