@import "../settings/variable.scss";

.btn-container {
  width: 100%;
  text-align: end;
  position: relative;

  .buttons {
    position: absolute;
    z-index: 100;
    right: 0;

    @media screen and (max-width: 865px) {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

    button {
      margin: 1.25rem !important;
    }
  }
}

.table-container {
  tr {
    height: 52px !important;
  }

  td {
    vertical-align: middle !important;

    &:nth-last-child(1) {
      width: 13rem;
    }

    button {
      color: $color-white !important;
      margin: 0 1rem;
      padding: 0.25rem 0.75rem !important;
    }

    .btn-disabled {
      cursor: default !important;

      &::before {
        display: none;
      }
    }
  }
}
