.filePreview {
  width: 100%;
  max-width: 22rem;

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    width: 100%;
    text-align: center;
    font-style: italic;
  }
}
