.previewCard__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 180px;
    height: 180px;
    border-radius: 0.5rem;
    border: 1px solid black;
  }
}
