.mediaItem__container {
  margin-top: 0.5rem;
  text-align: center;
  position: relative;
  width: 180px;
  height: 180px;
}

.mediaItem__container span {
  font-size: 1rem;
  position: relative;
  line-height: 180px;
}

.mediaItem__frame {
  border: 0.1rem solid;
  border-color: #646777;
  height: 180px;
  width: 180px;
}

.mediaItem__blur {
  position: absolute;
  filter: blur(3px);
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
