.headerContent {
  display: flex;
  justify-content: space-between;
}

.header {
  width: 100%;
}

.showFrame {
  margin: 0.5rem 1rem auto auto;
}

.bolded {
  font-weight: bold;
}

.uploadText {
  text-align: left;
}

.marginBottom {
  margin-bottom: 30px !important;
}
