@use "@scss/settings/responsives.scss" as responsives;

.box {
  padding: 0;

  textarea {
    white-space: pre-line;
    resize: none;
    min-height: initial !important;
    height: initial !important;
    overflow: hidden;
  }
}

.fontOptions {
  > div {
    max-width: 45%;
  }
}

.colorOptions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10%;
}

.fieldNameplate {
  min-width: 18.75rem;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

@include responsives.resolution__laptop__s {
  .formNameplate {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 auto;
  }
}

@include responsives.resolution__mobile__s {
  .fieldNameplate {
    padding-right: 1.875rem;
  }
}
