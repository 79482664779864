.reactPlayer__wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  background-color: black;
}

.reactPlayer__video {
  max-height: 180px !important;
}
