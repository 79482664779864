.mediaItem__add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;

  svg {
    height: 120px;
    width: 120px;
  }
}
