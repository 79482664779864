@use "@scss/settings/responsives.scss" as responsives;

.buttonToolbar {
  width: 100%;
  display: flex;
  justify-content: center;
}

@include responsives.resolution__mobile {
  .buttonToolbar {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}

@include responsives.resolution__mobile__s {
  .buttonToolbar {
    padding-right: 1.875rem;
  }
}
