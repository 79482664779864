.lnr {
  padding-right: 0.2rem;
}

.button__toolbar {
  width: 100%;

  button {
    min-width: 100px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  margin-left: 0 !important;
}
