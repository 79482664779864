.media__plaque__container {
  margin: 0.5rem 0.5rem 0 0.5rem;
  border-color: #646777;
  border: 0.1rem solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 50px;

  span {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: bold;
    text-align: center;
  }

  img {
    max-width: 180px;
    max-height: 50px;
  }
}

.media__plaque__podium {
  height: 150px !important;

  span {
    font-size: 1.5rem;
  }

  img {
    max-height: 150px !important;
  }
}

.media__plaque__default {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0.5rem 0 0.5rem;
  border-color: #646777;
  border: 0.1rem solid;
  width: 180px;
  height: 150px;

  svg {
    height: 45px;
    width: 45px;
  }
}

.media__plaque__blur {
  filter: blur(2px);
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.media__plaque__videoStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}
