.media__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 0 1rem 0;
  width: 200px;

  border-radius: 0.5rem;
  border: 0.1rem solid;
  border-color: #646777;
}
