@use "@scss/settings/mixins" as mixins;
@use "@scss/settings/responsives" as responsives;

.footer {
  @include mixins.flex_container(row, center, center);
  height: 5rem;
  font-size: 0.65rem;
  padding: 0 2rem;
  color: black;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 101;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.11);

  @include responsives.resolution__mobile {
    height: 9rem;
  }

  .footerBody {
    @include mixins.flex_container(column, center, center);

    @include responsives.resolution__8k {
      width: 140rem;
    }

    @include responsives.resolution__4k {
      width: 110rem;
    }

    @include responsives.resolution__laptop__l {
      width: 76rem;
    }

    @include responsives.resolution__laptop__m {
      width: 53rem;
    }

    @include responsives.resolution__laptop__s {
      width: 100%;
    }
  }

  .footerLine {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;

    @include responsives.resolution__mobile {
      @include mixins.flex_container(column, center, center);
      padding-top: 0.75rem;

      .footerLogo {
        height: 24px;
        margin-bottom: 0.5rem;
      }
    }

    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
