@mixin get_animation($bg-color) {
  &::before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: $bg-color;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none !important;

    &:before {
      height: 500%;
      width: 225%;
    }
  }
}

.switch {
  -webkit-box-align: center;
  display: flex;
  align-items: stretch;
  align-content: center;
  position: relative;
  white-space: nowrap;
  background: #ffffff;
  border-radius: 5px;
  height: 38px;
  border: 1px solid #dddddd;

  .innerInput {
    appearance: none;
    width: 100%;
    height: 100% !important;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0px;
    padding: 0px;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 5px;

    &:focus {
      border-color: transparent !important;
    }

    &:not(:checked) ~ .left {
      background: #fc9f90;
      color: #ffffff;
      border-right: 1px solid #dddddd;
      padding: 7px 15px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      position: relative;
      overflow: hidden;
      transition: all 0.4s;
      z-index: 0;
      @include get_animation(#f6816e);
    }

    &:checked ~ .right {
      background: #70bbfd;
      color: #ffffff;
      border-left: 1px solid #dddddd;
      padding: 7px 15px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      position: relative;
      overflow: hidden;
      transition: all 0.4s;
      z-index: 0;
      @include get_animation(#3ea3fc);
    }
  }

  span {
    width: 75px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    display: inline-block;
    padding: 7px 15px;
    user-select: none;
    color: rgba(51, 51, 51, 0.6);
    background: transparent;
    flex: 1;

    & .left {
      padding-right: 8px;
    }

    & .right {
      background: rgb(255, 255, 255);
      box-shadow: rgb(0 0 0 / 20%) 0px 0px 2px;
      color: rgb(51, 51, 51);
      padding: 7px 15px;
      transition: all 100ms ease-out 0s;
    }
  }
}
